<template>
    <div class='main'>
        <div>
            <Title >集团简介</Title>
            <!-- <ImagePreview class="wow slideInLeft img" :src="img1" :srcList="imgList1"></ImagePreview> -->
            <article class="box">
                <section class="content wow slideInRight">
                    <Pgp class="item">
                       厦门历思科技服务有限公司（简称历思）是一家拥有多专业物证优势技术的国家级高新技术企业，主要从事研发与技术创新、投融资与集团化管理，为历思体系内机构提供业务与发展支撑。
                    </Pgp>
                    <Pgp class="item">
                        目前历思旗下有3家司法鉴定所、1家检测集团公司、1家生物科技公司、1家价值评估公司。其中，福建历思司法鉴定所是国内司法鉴定管理体制改革后成立的第一批独立第三方司法鉴定机构；拥有3100平米大型物证中心、1500平米国家标准实验室；持有17大项执业资质（基本覆盖司法鉴定各领域），是目前国内资质最齐全的司法鉴定机构之一。
                    </Pgp>
                    <Pgp class="item">
                        “智慧物证，可信鉴定”，历思多年来致力于发展智慧物证科技，为社会提供可信鉴定服务。立足原发性创新，打造物证技术产研融合体系。
通过物证鉴定综合服务与配套软硬件产品的有机结合，历思及其旗下机构在多个领域建立了独特的竞争优势，可为客户提供集咨询、检测、鉴定、设备及软件为一体的全面解决方案。

                    </Pgp>
                </section>
            </article>
            <Title >组织架构</Title>
            <ImagePreview class="img2 wow slideInLeft" :src="img2" :srcList="imgList2"></ImagePreview>
        </div>

        <!-- <Carousel class="footer"></Carousel> -->

    </div>
</template>
<script>
import img1 from "@/assets/Goin/introduce/1.png"
import img2 from "@/assets/Goin/introduce/2.png"
// import Carousel from "@/components/Carousel"
export default {
    components:{
        // Carousel
    },
    data(){
        return {
            img1:img1,
            img2:img2,
            imgList1:[img1],
            imgList2:[img2]
        }
    },
}
</script>
<style scoped>
.main{
    margin:0 auto;
    width:80vw;
    max-width:1600px;
}
.img{
    width: 25vw;
    margin: 0 auto;
    min-width:222px;
    max-width:400px;
}
.img2{
    width: 50vw;
    margin: 0 auto;
    min-width:222px;
    max-width:800px;
    margin-bottom:2rem;
}
.box{
    margin:0 auto;
    width:80vw;
    min-width:222px;
    font-size:1rem;
    margin-top:2rem;
    max-width:1600px;
}
/* .item{
    margin-bottom:2rem;
} */
/* .content{
    text-align:left;
    letter-spacing:3px;
} */
.footer{
    margin-bottom:2rem;
}
@media screen and ( max-width:440px){
    
}
</style>